<template>
  <div>
    <el-button type="info"
              style="height: 28px"
               icon="el-icon-upload2"
               @click="exportFile">{{action || "导出"}}</el-button>
    <div tabindex="-1"
         role="dialog"
         aria-modal="true"
         aria-label="提示"
         class="el-message-box__wrapper"
         style="z-index: 2008;"
         v-show="exportFileNameShow">
      <div class="el-message-box">
        <div class="el-message-box__header">
          <div class="el-message-box__title">
            <!----><span>提示</span></div>
          <button type="button"
                  aria-label="Close"
                  class="el-message-box__headerbtn"></button>
        </div>
        <div class="el-message-box__content">
          <!---->
          <div class="el-message-box__message">
            <div class="exportName">
              <label>文件名</label>
              <input type="text"
                     autocomplete="off"
                     class="el-input__inner"
                     :class="invalid"
                     v-model="exportFileName">
            </div>
            <label style="visibility: hidden;">文件名</label>
            <span style="padding-left: 10px;line-height: 30px"
                  class="el-message-box__errormsg"
                  v-show="exportFileName ==''">文件名不能为空</span>
          </div>
        </div>
        <div class="el-message-box__btns">
          <button type="button"
                  class="el-button el-button--default el-button--small"
                  @click="cancel"><span>
              取消
            </span></button>
          <button type="button"
                  class="el-button el-button--default el-button--small el-button--primary "
                  @click="confirm"><span>
              确定
            </span></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import exportFile from '@/components/exportFile/exportFile.vue'
import {
  dateFormat
} from '@/common/js/public.js'

export default {
  name: "exportFile",
  props: ['exportData', 'action'],
  data() {
    return {
      exportFileName: '',
      exportFileNameShow: false
    }
  },
  components: {
    // exportFile
  },
  computed: {
    invalid() {
      return {
        invalid: this.exportFileName == ''
      }
    }
  },
  methods: {
    // 导出
    exportFile() {
      this.$emit("exportFile")
      this.exportFileNameShow = true;
      this.exportFileName = this.exportData.data.name + dateFormat(new Date(), 'yyyyMMddHHmmss');
    },
    cancel() {
      this.exportFileNameShow = false;
    },
    confirm() {
      if (this.exportFileName == '') {

      } else {
        this.exportData.data.fileName = this.exportFileName
        this.exportFileNameShow = false;
        this.exportFileHttp()
      }
    },
    exportFileHttp() {
      this.$axios[this.exportData.methods](this.exportData.url, {
        data: this.exportData.data
      }).then((res) => {
        if (res.state == 0) {
          this.$EventBus.$emit('downListShow')
          this.$message({
            type: 'success',
            message: '成功添加下载列表！'
          });
        } else {
          this.$alert(res.desc)
        }
      })
    }
  },
  mounted() {
  }
}
</script>
<style scoped>
.exportName {
  font-size: 14px;
}

.exportName input.invalid,
.exportName input.invalid:focus {
  border-color: #f56c6c;
}

.exportName label {
  margin-right: 12px;
}

.exportName input {
  width: 280px;
  -webkit-appearance: none;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
</style>
